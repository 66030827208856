@import "~bootstrap/scss/bootstrap";
@import "_fonts";
@import "_mixins";

/*==== GLOBAL ====*/

body {
  font-weight: 400;
}

.wrapper {
  overflow: hidden;
  background: #fff url("../images/top_pic.jpg") no-repeat;
  background-position: top center;
  background-size: 100% auto;
  min-width: 320px;

  @include res(1200) {
    background-size: 140% auto;
  }

  @include res(680) {
    background-position: 60% 0;
    background-size: 160% auto;
  }

  @include res(480) {
    background-position: 72% 0;
    background-size: 200% auto;
  }
}

.container {
  max-width: 1190px;
  padding-left: 10px;
  padding-right: 10px;

  @include res(1400) {
    max-width: 1140px;
  }

  @include res(1200) {
    max-width: 960px;
  }

  @include res(991) {
    max-width: 720px;
  }

  @include res(767) {
    max-width: 540px;
  }
}

.top {
  padding-top: 30px;

  @include res(767) {
    padding-top: 10px;
  }

  &-content {
    margin-bottom: 200px;

    @include res(1200) {
      margin-bottom: 120px;
    }

    @include res(767) {
      margin-bottom: 75px;
    }

    @include res(575) {
      margin-bottom: 45px;
      flex-direction: column-reverse;
    }
  }

  .logo {
    margin-bottom: 65px;

    @include res(767) {
      margin-bottom: 35px;
    }

    @include res(575) {
      margin-bottom: 0;
    }

    img {
      display: block;

      @include res(767) {
        width: 125px;
      }

      @include res(575) {
        width: 100px;
        margin-left: 0;
      }
    }
  }

  .title-wrap {
    flex: 0 0 50%;

    @include res(991) {
      flex: 0 0 65%;
    }

    @include res(575) {
      flex: 0 0 100%;
    }
  }

  h1 {
    font-size: 44px;
    line-height: 56px;
    font-weight: 700;
    margin-bottom: 0;
    font-family: $Oswald;
    text-transform: uppercase;
    color: #333;

    @include res(991) {
      font-size: 36px;
      line-height: 48px;
    }

    @include res(575) {
      font-size: 28px;
      line-height: 43px;
    }

    span {
      color: #006fd0;
      display: block;
    }
  }

  &-underline {
    width: 370px;
    height: 2px;
    background-color: #a5d652;
    margin: 30px 0;

    @include res(575) {
      width: 100%;
      margin: 25px 0;
    }
  }

  &-content-btn_holder {
    position: relative;
    width: fit-content;

    @include res(575) {
      margin: auto;
    }

    &:hover {

      .underlayer {
        left: -12px;
      }
    }

    .underlayer {
      position: absolute;
      display: block;
      width: 240px;
      height: 54px;
      top: 0;
      left: 12px;
      background: url('../images/btn-bg.png') no-repeat;
      background-size: cover;
      opacity: .7;
      z-index: 0;
      transition: left .25s linear;
    }
  }

  &-content-btn {
    display: block;
    position: relative;
    width: 240px;
    height: 54px;
    font-size: 16px;
    line-height: 54px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    background: url('../images/btn-bg.png') no-repeat;
    background-size: cover;
    z-index: 1;
    font-weight: 500;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  .car {
    padding-top: 10px;
    position: relative;

    @include res(575) {
      flex: 0 0 100%;
      padding-top: 25px;
    }

    img {
      position: absolute;
      display: block;
      height: 450px;
      top: 0;
      left: -60px;

      @include res(1200) {
        height: 420px;
        left: -10px;
      }

      @include res(767) {
        height: 270px;
        left: 0;
      }

      @include res(575) {
        position: static;
        height: auto;
        width: 100%;
        margin-bottom: -20px;
      }
    }
  }
}

h2 {
  color: #333;
  font-size: 28px;
  font-weight: 700;
  font-family: $Oswald;
  text-transform: uppercase;
  margin-bottom: 25px;

  @include res(1200) {
    font-size: 24px;
  }

  @include res(991) {
    position: relative;
    padding-left: 55px;
    line-height: 36px;
  }

  @include res(767) {
    padding-left: 50px;
  }

  @include res(575) {
    font-size: 18px;
    text-align: center;
    line-height: 26px;
    padding: 0;
  }

  img {
    position: relative;
    top: -25px;

    @include res(991) {
      position: absolute;
      left: 0;
    }

    @include res(767) {
      top: -15px;
      height: 46px;
    }

    @include res(575) {
      display: none;
    }
  }
}

.form {
  margin: 0 -20px;

  @include res(1200) {
    margin: 0 -40px;
  }

  @include res(767) {
    margin: 0;
  }
}

.advantages {

  h2 {
    margin: 75px 0 50px;

    @include res(991) {
      margin: 50px 0;
    }

    @include res(767) {
      margin: 15px 0 40px;
    }

    @include res(575) {
      font-size: 24px;
      margin: 10px 0 35px;
    }
  }

  &-item {

    .icon-col {

      @include res(991) {
        margin: auto;
        margin-bottom: 25px;
      }

      @include res(575) {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .advantages-content {
        display: flex;
      }

      img {
        display: block;
        margin-right: 30px;

        @include res(767) {
          width: 50px;
          margin-right: 25px;
        }

        @include res(575) {
          width: 40px;
          margin-right: 20px;
          align-self: center;
        }
      }

      span {
        display: block;
        font-size: 21px;
        line-height: 32px;
        color: #404040;
        font-family: $Oswald;
        align-self: center;

        @include res(575) {
          font-size: 18px;
          line-height: 26px;
        }

        br {

          @include res(767) {
            display: none;
          }
        }
      }
    }
  }
}

footer {
  position: relative;
  margin-top: 70px;
  height: 125px;

  @include res(991) {
    margin-top: 35px;
    height: 100px;
  }

  @include res(767) {
    margin-top: 30px;
    height: 75px;
  }

  @include res(575) {
    height: 110px;
    margin-top: 45px;
  }

  .container {
    height: 100%;
    border-top: 2px solid #a5d652;

    @include res(767) {
      padding: 0;
    }

    @include res(575) {
      flex-direction: column;
      justify-content: center !important;
      border-width: 1px;
    }
  }
}

.copyrights {
  align-self: center;

  @include res(575) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  img {
    margin-right: 25px;

    @include res(767) {
      display: block;
      width: 90px;
      margin-right: 15px;
    }

    @include res(575) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  span {
    font: 400 14px/21px Arial;
    color: #999;

    @include res(767) {
      font-size: 12px;
      line-height: 18px;
    }

    @include res(575) {
      font-size: 10px;
      line-height: 16px;
    }

    br {

      @include res(575) {
        display: none;
      }
    }
  }
}

.footer-links {
  align-self: center;

  a {
    color: #006fd0;
    font: 400 14px/21px Arial;
    padding: 0 15px;

    @include res(767) {
      font-size: 12px;
      line-height: 18px;
    }

    &:first-child {

      @include res(767) {
        padding-left: 0;
      }
    }

    &:last-child {
      padding-right: 0;
    }

    & + a {
      border-left: 1px solid #006fd0;
    }
  }
}
